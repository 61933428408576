import * as React from "react"
import { graphql } from "gatsby"
import Flickity from 'react-flickity-component'
//import ScrollOut from 'scroll-out'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import BlogCard from '../components/blog-card'

import img1 from '../images/nakji.starkware.banner.png'
import img2 from '../images/img-1.jpg'
import img3 from '../images/img-2.jpg'
import img4 from '../images/img-4.jpg'


const blogPosts = [{
  img: img1,
  title: "Nakji Network implements StarkEx ecosystem to Deliver Scalable Web 3.0 Data",
  link: "https://nakji.medium.com/nakji-network-implements-starkex-ecosystem-with-plans-to-support-starknet-next-to-deliver-scalable-97ef9be10e94",
}, {
  img: img2,
  title: "Web 3.0 and the Decentralized Data Infrastructure of the Future",
  link: "https://nakji.medium.com/web-3-0-and-the-decentralized-data-infrastructure-of-the-future-93c5d6f78f50"
}, {
  img: img3,
  title: "NAKJI NETWORK Completes a $8.8 Million Private Round Of Fundraising",
  link: "https://nakji.medium.com/nakji-network-completes-an-8-8-million-private-round-of-fundraising-7c5877a7707e"
}, {
  img: img4,
  title: "Why is Blockchain the next big thing for data science?",
  link: "/",
}]

const Landing = ({data, location, pageContext}) => {
  const { pageContent } = pageContext

  const { hero, productsHeading, products, blogHeading, about } = pageContent
  console.log(about)

  const siteTitle = data.site.siteMetadata?.title || `Title`

  //ScrollOut({
    //// targets: '#blog',
    //// scrollingElement: '#blog',
    //onShown: function (el) {
      //console.log(el.classList.contains("scrolled"))
      //if (!el.classList.contains("scrolled")) {
        //let i = 0;
        //let txt = el.innerText; [> The text <]
        //let speed = 40; [> The speed/duration of the effect in milliseconds <]
        //let eli = el;
        //eli.innerHTML = '';
        //function typeWriter() {
          //if (i < txt.length) {
            //eli.innerHTML += txt.charAt(i);
            //i++;
            //setTimeout(typeWriter, speed);
          //}
        //}
        //typeWriter();
        //el.classList.add("scrolled");
      //}
    //}
  //});

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={siteTitle} />

      {/*<!-- ================== Heros Section Start ==================== -->*/}
      <section
        className="w-full bg-cover bg-center bg-[url('./images/blockchain-technology-background-gradient-blue-mobile.png')] lg:bg-[url('./images/blockchain-technology-background-gradient-blue.png')] relative">
        {/*<!-- Background Gradient Color -->*/}
        <div className="absolute left-0 z-0 w-1/2 h-full bg-gradient-to-r from-black"></div>

        <Header />

        {/*<!-- Heors Start  -->*/}
        <main className="container xl:max-w-[1440px] mx-auto px-4 xl:px-20 pt-[340px] lg:pt-48 pb-20 lg:pb-60 relative">
          <div className="max-w-3xl mx-auto text-center lg:text-left lg:ml-0">
            <p className="text-xs font-medium text-blue-10 uppercase tracking-[0.17em] mb-[10] md:mb-0"
              data-scroll="out" data-splitting="">{hero.preheader}</p>
            <h1 className="text-[28px] sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold leading-8 lg:leading-[65px] text-white mb-5"
              data-scroll="out" data-splitting="">
              {hero.header}</h1>
            <p className="mb-10 text-base sm:text-lg md:text-xl lg:text-2xl text-white-10 lg:leading-8 lg:max-w-2xl"
              data-scroll="out" data-splitting="">
              {hero.subheader}</p>

            <a href={hero.link}
              className="bg-blue-80 text-xs font-medium tracking-[0.12em] text-white uppercase px-5 py-4 shadow-10">explore</a>
          </div>
        </main>
        {/*<!-- Heors End  -->*/}
      </section>
      {/*<!-- ================== Heros Section End ==================== -->*/}



      {/*<!-- ================== AboutUS Section Start ==================== -->*/}
      <section id="about" className="container xl:max-w-[1440px] mx-auto px-4 xl:px-20 py-20 lg:py-[120px] scroll-mt-12">
        <div className="grid lg:grid-cols-12 gap-6">
          <div className="lg:col-start-2 lg:col-span-8">
            <p className="text-xs font-medium uppercase text-blue-10 mb-[10px] lg:mb-0" data-scroll="out"
              data-splitting="">about us</p>
            <p
              dangerouslySetInnerHTML={{
                  __html: about,
                }}
              className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-[30px] xl:leading-[37px] text-blue-90 mb-10" />
            <a href="https://jobs.lever.co/Blep" className="flex items-center text-sm uppercase text-blue-80">
              <span data-scroll="out" data-splitting=""> join our team</span>
              <svg data-name="Group 7" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                viewBox="0 0 20 20">
                <path data-name="Path 10" d="M0 0h20v20H0Z" fill="none" />
                <path data-name="Line 4" fill="none" stroke="#1743d3" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="1.5" d="m14 6-8 8" />
                <path data-name="Path 11" d="M6.695 5.858h7.446v7.446" fill="none" stroke="#1743d3"
                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
              </svg>
            </a>
          </div>
        </div>
      </section>
      {/*<!-- ================== AboutUS Section End ==================== -->*/}



      {/*<!-- ================== Products Section Start ==================== -->*/}
      <section id="products"
        className="container xl:max-w-[1440px] mx-auto px-4 xl:px-20 pb-20 lg:pb-[135px] scroll-mt-12 page">
        <p className="text-xs font-medium uppercase text-blue-10 tracking-[0.17em] mb-[10] md:mb-0 w-full" data-scroll="out"
          data-splitting="">products</p>
        <h1 className="text-[25px] sm:text-3xl md:text-4xl lg:text-[40px] font-bold max-w-4xl w-full mb-9 sm:mb-12 md:mb-16 lg:mb-20"
          data-scroll="out" data-splitting="">
          {productsHeading}</h1>

        <div className="border-t border-blue-10">
          {products.map(product => (
            <ProductItem key={product.title} {...product} />
          ))}
        </div>
      </section>
      {/*<!-- ================== Products Section End ==================== -->*/}




      {/*<!-- ================== Blog Section Start ==================== -->*/}
      <section id="blog" className="container xl:max-w-[1440px] mx-auto px-4 xl:px-20 pb-20 lg:pb-[120px] scroll-mt-12">

        <div className="flex items-end justify-between mb-10">
          <div className="w-full lg:w-7/12">
            <p className="text-xs font-medium uppercase text-blue-10 tracking-[0.17em] mb-[10] md:mb-0"
              data-scroll="out" data-splitting="">blog
            </p>
            <h1 className="text-[25px] sm:text-3xl md:text-4xl lg:text-[40px] font-bold" data-scroll="out"
              data-splitting="">
              {blogHeading}</h1>
          </div>
          <div className="hidden lg:inline">
            <a href="https://nakji.medium.com/"
              className="inline-flex items-center px-5 py-4 uppercase bg-transparent border-2 rounded-sm border-blue-80 space-x-2">
              <span>all articles</span>
              <svg id="Group_69" data-name="Group 69" xmlns="http://www.w3.org/2000/svg" width="15.995"
                height="15.995" viewBox="0 0 15.995 15.995">
                <path id="Path_10" data-name="Path 10" d="M0,0H16V16H0Z" fill="none" />
                <line id="Line_4" data-name="Line 4" x1="6.398" y2="6.398" transform="translate(4.799 4.799)"
                  fill="none" stroke="#1743d3" strokeLinecap="round" strokeLinejoin="round"
                  strokeWidth="1.5" />
                <path id="Path_11" data-name="Path 11" d="M8,7h5.955v5.955" transform="translate(-2.645 -2.315)"
                  fill="none" stroke="#1743d3" strokeLinecap="round" strokeLinejoin="round"
                  strokeWidth="1.5" />
              </svg>
            </a>
          </div>
        </div>

        <Flickity options={{ "cellAlign": "left", "contain": true, "pageDots": false, "prevNextButtons": false, "freeScroll": false }}>
          {blogPosts.map(post => (
            <BlogCard key={post.title} {...post} />)
          )}
        </Flickity>
      </section>
      {/*<!-- ================== Blog Section End ==================== -->*/}

    </Layout>
  )
}

export default Landing

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
}
`

const ProductItem = ({title, desc, link}) => (
  <div className="flex flex-wrap py-5 border-b lg:items-center border-blue-10">
    <div className="w-full lg:w-4/12 mb-[10px] lg:mb-0">
      <div className="flex items-center justify-between">
        <h2 className="text-xl md:text-2xl lg:text-[30px] font-bold text-blue-90" data-scroll="out"
          data-splitting="">{title}</h2>
        <a href="https://nakji.network/" className="lg:hidden">
          <svg data-name="Group 102" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
            viewBox="0 0 24 24">
            <path data-name="Path 10" d="M0 0h24v24H0Z" fill="none" />
            <path data-name="Line 4" fill="none" stroke="#1f2937" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5" d="m16.818 7.181-9.637 9.637" />
            <path data-name="Path 11" d="M8.016 7.014h8.97v8.97" fill="none" stroke="#1f2937"
              strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          </svg>
        </a>
      </div>
    </div>

    <div className="w-full lg:w-5/12">
      <p className="w-full text-sm md:text-base lg:text-lg xl:text-xl text-blue-10 xl:pr-3" data-scroll="out"
        data-splitting="">{desc}</p>
    </div>
    <div className="hidden ml-auto lg:flex">
      <a href={link} className=""><svg id="Group_70" data-name="Group 70"
        xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <path id="Path_10" data-name="Path 10" d="M0,0H48V48H0Z" fill="none" />
        <line id="Line_4" data-name="Line 4" x1="19.262" y2="19.262"
          transform="translate(14.369 14.369)" fill="none" stroke="#1f2937" strokeLinecap="round"
          strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Path_11" data-name="Path 11" d="M8,7H25.929V24.929"
          transform="translate(8.038 7.033)" fill="none" stroke="#1f2937" strokeLinecap="round"
          strokeLinejoin="round" strokeWidth="1.5" />
      </svg>
      </a>
    </div>
  </div>
)
